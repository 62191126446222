import { ref, watch, computed } from '@vue/composition-api'
import axios from '@/libs/axios'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useBanksList() {
  // Use toast
  const toast = useToast()
  const refBanksListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name' },
    { key: 'accountNumber' },
    { key: 'type' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalBanks = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const recordDeleted = ref(false)
  
  const dataMeta = computed(() => {
    const localItemsCount = refBanksListTable.value ? refBanksListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalBanks.value,
    }
  })
  const refetchData = () => {
    console.log(refBanksListTable)
    if (refBanksListTable.value){
      refBanksListTable.value.refresh()
    }
  }

  const fetchBanks = (ctx, callback) => {
    axios.get(`/bank?q=${searchQuery.value}&perPage=${perPage.value}&page=${currentPage.value}&sortBy=${sortBy.value}&sortDesc=${isSortDirDesc.value}`)
    .then((response) => {
      const { banks, total } = response.data
      callback(banks)
      totalBanks.value = total
    })
    .catch(error => {
      console.log(error)
      toast({
        component: ToastificationContent,
        props: {
          title: error.response.data.msg || 'Error obtaining bank',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }
  const deleteBank = (id) => {
    axios.delete(`/bank/${id}`)
    .then((response) => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Bank deleted',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
    })
    .catch(error => {
      console.log(error)
      toast({
        component: ToastificationContent,
        props: {
          title: error.response.data.msg || 'Error deleting bank',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }
  const updateBanksStatus = (id,newStatus,callback) =>{
    axios.put(`/bank/${id}`,{ validated: newStatus })
    .then((response) => {
      callback()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Bank updated',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
    })
    .catch(error => {
      console.log(error)
      toast({
        component: ToastificationContent,
        props: {
          title: error.response.data.msg || 'Error updating banks list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }
  


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchBanks,
    updateBanksStatus,
    tableColumns,
    perPage,
    currentPage,
    refBanksListTable,
    totalBanks,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refetchData,
    deleteBank,
    recordDeleted
  }
}
