<template>

  <div>
    <!-- Filters -->
    <!-- <Banks-list-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    /> -->
    <bank-list-add-new
      :is-add-new-bank-sidebar-active.sync="isAddNewBankSidebarActive"
      @refetch-data="refetchData"
    />
    
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="type the bank name here and search "
              />
               <!-- " -->
              <div v-if="user.clients !== 'admin'">
                <b-button
                  variant="primary"
                  @click="isAddNewBankSidebarActive = true"
                >
                  <span class="text-nowrap">Add Bank</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refBanksListTable"
        class="position-relative"
        :items="fetchBanks"
        :fields="tableColumns"
        primary-key="_id"
        responsive
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <div>
              <b-dropdown-item @click="$bvModal.show('edit-modal')" >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
            </div>
            <div>
              <b-dropdown-item @click="$bvModal.show('delete-modal')" >
                <feather-icon icon="TrashIcon" class="text-danger"/>
                <span class="align-middle ml-50" style="color:red">Delete</span>
              </b-dropdown-item>
            </div>

          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalBanks"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal id="delete-modal" title="delete" hide-footer hide-header>
      <p class="my-4">¿ Are you sure that you whant to delete this bank from database ?</p>
        <b-button 
          class="mt-3 mx-1"
          variant="outline-success" 
          @click="$bvModal.hide('delete-modal')"
        >
        No
        </b-button>
        <b-button 
          class="mt-3"
          variant="danger" 
          @click="deleteBankAndHideModal()"
        >
        Yes Delete
        </b-button>    
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useBanksList from './useBanksList'
import BankListAddNew from './BanksListAddNew.vue'

import { ref } from '@vue/composition-api'

export default {
  components: {
    BankListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  setup() {     
    const statusOptions = [
      { label: 'True', value: true },
      { label: 'False', value: false },
    ]
    const color = {
      validated: 'success',
      'no validated': 'danger',
      waiting: 'warning'
    }
    const isAddNewBankSidebarActive = ref(false)
    const bankSelected = ref('')

    const {
      fetchBanks,
      updateBanksStatus,
      tableColumns,
      perPage,
      currentPage,
      deleteBank,
      refBanksListTable,
      totalBanks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      recordDeleted
    } = useBanksList()
  
    return {
      // Sidebar
      fetchBanks,
      bankSelected,
      updateBanksStatus,
      tableColumns,
      perPage,
      currentPage,
      refBanksListTable,
      totalBanks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      deleteBank,
      recordDeleted,
      isAddNewBankSidebarActive
    }
  },
  watch:{
    currentPage(){
      this.$refs.refBankListTable.refresh()
    }, 
    perPage(){
      this.$refs.refBankListTable.refresh()
    }, 
    searchQuery(){
      this.$refs.refBanksListTable.refresh()
    },
    recordDeleted(){
      this.$refs.refBankListTable.refresh()
    }
  },
  methods: {
    updateQuery(){
      this.$refs.refSearchQuery
      //this.searchQuery = this.$refs.refSearchQuery.value
      console.log(this.$refs.refSearchQuery)
    },
    async deleteBankAndHideModal(id){
      await this.deleteBank(this.bankSelected)
      this.$bvModal.hide('delete-modal') 
      this.$refs.refBanksListTable.refresh()
    }
  },
  computed:{
    user(){
      const user = JSON.parse(localStorage.getItem('userData'))
      return user
    }
  }
  /*
  computed: {
    canDelete() {
      var actions = this.roleActions()
      if (actions) {
        return actions.delete
      }
    },
    canCreate() {
      var actions = this.roleActions()
      if (actions) {
        return actions.create
      }
    },
    canEdit() {
      var actions = this.roleActions()
      if (actions) {
        return actions.update
      }
    },
  },
  methods: {
    roleActions() {
     const permissions = JSON.parse(sessionStorage.getItem('permissions')) 
      var actions = permissions.find(permission => permission.path == this.$route.path)
      return actions
    },
  }
  */
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.firstletter::first-letter{
  color: #efb810 !important;
  font-size:2em;
	font-family:serif;
  text-transform: capitalize;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>