<template>
  <b-sidebar
    id="add-new-role-sidebar"
    :visible="isAddNewBankSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-bank-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Bank
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Role name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="bankData.name"
                placeholder="bank name"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="accountNumber"
            rules="required"
          >
            <b-form-group
              label="Account Number"
              label-for="accountNumber"
            >
              <b-form-input
                id="accountNumber"
                v-model="bankData.accountNumber"
                :state="getValidationState(validationContext)"
                trim
                placeholder="bank account number"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

           <!-- Modules -->
          <validation-provider
            #default="validationContext"
            name="type"
            rules="required"
          >
            <b-form-group
              label="type"
              label-for="type_id"
            >
              <b-form-select
                input-id="type_id"
                v-model="bankData.type"
                :options="bankTypes"
                value-field="_id"
                text-field="type"
                aria-placeholder="select the bank type"
                :state="getValidationState(validationContext)"
              />
              
              <template #code>
                {{ codeMultiple }}
              </template>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>

            </b-form-group>
          </validation-provider>
 
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
          
        </b-form>
      </validation-observer>
      
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { codeMultiple } from '../../../forms/form-element/form-select/code'
import axiosIns from '@/libs/axios'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSelect,
    
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewBankSidebarActive',
    event: 'update:is-add-new-bank-sidebar-active',
  },
  props: {
    isAddNewBankSidebarActive: {
      type: Boolean,
      required: true,
    },
    
  },
  data() {
    return {
      required,
      alphaNum,
      bankExist: '',
      codeMultiple,
    }
  },
  setup(props, { emit }) {
    const bankTypes = {
      "saving": 'ahorro',
      "common": 'corriente',
    }
    const blankBankData = {
      name: '',
      accountNumber: '',
      type:''
    }
    const toast = useToast()
    const isAddNewBankSidebarActive = ref(props.isAddNewBankSidebarActive)
    const bankData = ref(JSON.parse(JSON.stringify(blankBankData)))
    const resetBankData = () => {
      bankData.value = JSON.parse(JSON.stringify(blankBankData))
    }

    const onSubmit = () => {
      axiosIns.post('/bank', bankData.value)
      .then(response => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Bank created',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        emit('refetch-data')
        emit('update:is-add-new-bank-sidebar-active',false)
      })
      .catch(error =>{
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.msg || 'Error creating bank',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetBankData)

    return {
      bankData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      bankTypes
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-role-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
